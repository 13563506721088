
import Vue from 'vue';
import { FacebookAccount, FacebookCampaign } from '../../../types/ads';

export default Vue.extend({
  props: ['facebookAccount', 'facebookConnectorAdvertiser'],
  data: (): {
    facebookData: object;
    search: string;
  } => ({
    facebookData: null,
    search: null,
  }),
  methods: {
    toggleCampaign(campaign, account) {
      this.$emit(
        'toogle-campaign',
        campaign,
        { account_id: account.account_id, account_name: account.account_name },
        {
          facebook_account_id: this.facebookAccount.facebook_account_id,
          facebook_account_name: this.facebookAccount.facebook_account_name,
        },
      );
    },
    addStatusesToData(facebookAccount) {
      // facebookAccount -> fbAccount current
      // facebookConnectorAdvertiser -> [ fbAccount ]
      if (!this.facebookConnectorAdvertiser) {
        return [];
      }
      // find current fbAccount in all connected connectors
      const connectorFbAccount = this.facebookConnectorAdvertiser.find(
        connector => connector.facebook_account_id === facebookAccount.facebook_account_id,
      );

      if (!connectorFbAccount) {
        return facebookAccount;
      }
      // iterate through found current connector accounts
      connectorFbAccount.accounts.map(connectedAccount => {
        // check if connectedAccount is found in connected facebookAccount.accounts
        const foundAccount = facebookAccount.accounts.find(a => a.account_id === connectedAccount.account_id);
        if (foundAccount) {
          // check if all campaigns are connected
          let checkedCampagins = 0;
          foundAccount.campaigns.map(foundAccountCampaign => {
            const foundAccountHasThisCampaign = connectedAccount.campaigns.find(
              connectedAccountCampaign => foundAccountCampaign.campaign_id === connectedAccountCampaign.campaign_id,
            );
            // mark connected campaign
            if (foundAccountHasThisCampaign) {
              foundAccountCampaign.status = 'checked';
              checkedCampagins += 1;
            }
            return foundAccountHasThisCampaign;
          });
          let status = 'partial';
          if (checkedCampagins === foundAccount.campaigns.length) {
            status = 'checked';
          }
          foundAccount.status = status;
        }
      });
      return facebookAccount;
    },
  },
  computed: {
    searchText(): string {
      return this.facebookAccount.facebook_account_name
        ? `Search ${this.facebookAccount.facebook_account_name} accounts and campaigns`
        : 'Search for accounts and campaigns';
    },
    filteredData(): object[] {
      const dataCopy = JSON.parse(JSON.stringify(this.facebookAccount));
      const filteredAccounts = [];
      // search by account_name, account_id, campaign_name and campaign_id
      if (this.search) {
        const lowerCaseSearch = this.search.toLowerCase();
        dataCopy.accounts.map(account => {
          const foundAccount =
            account.account_name.toLowerCase().includes(lowerCaseSearch) ||
            account.account_id.toLowerCase().includes(lowerCaseSearch);
          if (foundAccount) {
            filteredAccounts.push(account);
          } else {
            const foundCampaigns = account.campaigns.filter(campaign => {
              return (
                campaign.campaign_name.toLowerCase().includes(lowerCaseSearch) ||
                campaign.campaign_id.toLowerCase().includes(lowerCaseSearch)
              );
            });
            if (foundCampaigns.length) {
              account.campaigns = foundCampaigns;
              filteredAccounts.push(account);
            }
          }
        });
        dataCopy.accounts = filteredAccounts;
      }
      const facebookAccount = this.addStatusesToData(dataCopy);
      return facebookAccount.accounts;
    },
  },
});
